<template>
  <div class="card shadow mt-4" style="padding-right:10px; padding-left:10px; padding-top:10px; padding-bottom:10px;">
       <h3 class="purple-title">Deleted Partners (Permanently Deleted After 90 days)</h3>
        <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
    <template #cell(summary)="row">       
        <button style="margin-left:5px;margin-top:5px;" class="btn btn-primary" @click="setRestoreItem(row.item)">Restore</button>
         <Modal v-model="showModal" title="Delete Property">
                <p>Are you sure you would like to restore this organization?</p>
                <button @click="showModal = false" class="btn purple-btn"> &nbsp;&nbsp; Back</button>
                <button @click="restoreItem()" style="float:right;" class="btn btn-success">Restore </button>
        </Modal>
      </template>


    </b-table>
    <div class="row">
        <div class="col-md-12">
                <a style="cursor:pointer; font-size:20px;" v-for="number in pageNumbers" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
  </div>
</template>

<script>
import {adminService} from '../../../_services/index'
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
export default {
     components:{
        'Modal':VueModal,
    },
     mounted(){
        adminService.getAllDeletedPartners()
        .then((response) => {
            this.items = response.data
        })
    },
    data(){
        return{
            isLoading: false,
            showModal: false,
            fullPage: true,
            items:[],
            itemToRestore: {},
            fields:[
                {
                    key: 'name', label: 'Name', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'address', label: 'Address', sortable: true, sortDirection: 'desc'
                },    
                {
                    key: 'regCode', label: 'Registration Code', sortable: true, sortDirection: 'desc'
                },      
                {
                    key: 'summary', label: ''
                }
            ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                pageNumbers:[]
        }
    },
    methods:{
        setPage: function(number){
            this.currentPage = number;
        },
        setRestoreItem: function(item){
            this.itemToRestore = item;
            this.showModal = true;
        },
        restoreItem: function(){
            
            adminService.restorePartner(this.itemToRestore.id)
            .then(() => {
                this.items = this.items.filter(thing => thing.id !== this.itemToRestore.id);
                this.showModal = false;
            })
        }
        
    }
}
</script>

<style>

</style>